<template lang="pug">
v-dialog(v-model='show', :width='width')
    v-card
        v-card-title {{ confirmTitle }}
        v-card-text {{ confirmText }}
        v-card-actions
            v-spacer
            v-btn(@click='cancel', text) Cancel
            v-btn(color='red', @click='confirm') {{ confirmButtonText }}
</template>

<script>
export default {
    props: {
        confirmButtonText: {
            type: String,
            default: 'Delete'
        },

        width: {
            type: Number,
            default: 200
        },

        confirmText: {
            type: String,
            default: 'Are you sure?'
        },

        confirmTitle: {
            type: String,
            default: 'Confirm Delete'
        },

        show: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        cancel () {
            this.$emit('cancel');
        },
        confirm () {
            this.$emit('confirm');
        }
    }
};
</script>